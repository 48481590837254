
import {useEffect, useState} from "react";
import {Container,Row,Col,Image} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageList from './pagelist'

import PocketBase from 'pocketbase';
import Info from '../info'
const info = Info();
const pb = new PocketBase(info.api);

function Notice(){

    const [itemlist, setItemlist] = useState([[]]);
    const [selectIdx, setSelectIdx] = useState(1);
    const [maxIdx, setMaxIdx] = useState(1);
    const maxPage = 50;

    useEffect(()=>{

        pb.collection('notice').getList(1, 1000, {
            filter: `created >= "2022-01-01 00:00:00"` ,
        })
        .then(json => {
            
            console.log(json.items);            
            const notices = json.items;
            let list = [];
            let push = [];
            for(let i=0; i<notices.length ;i++){
                notices[i].uid = notices[i].id
                notices[i].id = notices[i].index;
                list.push(notices[i]);
                
                if(list.length===maxPage){
                    push.push(list);
                    list = [];
                }               
            }
            if(list.length>0) push.push(list);

            //console.log(push);

            let max = push.length===0?1:push.length;

            setItemlist(push);
            setMaxIdx(max);

            let items = [];
            for (let number = 1; number <= max; number++) {
                items.push(number);
            }

        },erro=>{
            console.log('---------- '+erro);
            setSelectIdx(1);
        });

    },[])
    
    return(<>
        <div>    
            <PageList maxIdx={maxIdx} onChangePage={(idx)=>{
                setSelectIdx(idx);
              }} />        
            <Container >
            {
                itemlist.length===0 
                ? (<h1>Loading...</h1>) 
                :(
                    itemlist[selectIdx-1].map((item)=>(
                        
                        <Row className="border-top p-3" key={item.id} >
                            
                            <Col xs lg={3} >
                                <Link to={`/notice/${item.index}`}>
                                <Image src={`${info.cdn}notice/${item.img}`} />
                                </Link>
                            </Col>
                            <Col xs lg={9} >
                                <Link to={`/notice/${item.index}`}><p style={{fontSize:'1.5rem'}}>{item.title}</p></Link>
                                <p>{item.des}</p>
                            </Col>
                        </Row>                        
                    ))
                )             
            }
            </Container>            
        </div>
    </>)
}


export default Notice;