
/*/
import {Container,Row,Col,Button} from 'react-bootstrap';
import {useNavigate } from "react-router-dom";


function Menu(){
    let navigate = useNavigate();
    return (<>
        <Container >
            <Row>
                <Col>
                <div style={{margin:'5px'}}>
                        <Button className="nes-btn is-primary" onClick={()=>navigate('/')}>Home</Button>
                    </div>
                </Col>
            </Row>

        </Container>
    </>);
}
/** */
/** */
import {Container,Button} from 'react-bootstrap';
import {useNavigate } from "react-router-dom";

import Info from '../info'
const info = Info();


function Menu(){
    let navigate = useNavigate();
    return (<>
        <Container>
            <div style={{margin:'5px'}}>
                <Button className="nes-btn is-primary" onClick={()=>navigate('/')}>Home</Button>
                {/* <Button className="nes-btn is-primary" onClick={()=>navigate('/all')}>all</Button> */}
                {
                    info.typs.map(type=>(
                        <Button key={type} className="nes-btn is-primary" onClick={()=>navigate(`/${type}`)}>{type}</Button>
                    ))
                }
            </div>
        </Container>
    </>);
}
/**/
export default Menu;