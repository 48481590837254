
function Info() {
    return {
        api : 'https://5rbpdiisaa.execute-api.ap-northeast-2.amazonaws.com',
        //api : 'http://193.122.98.43:8090',        
        //api : 'https://emulator-charge.pockethost.io',
        cdn : 'https://pub-c6c3f428fbb545a1aad02997b7379538.r2.dev/',

        typs : ['arcade','nintendo','playstation','dos']
    }
}

export default Info 