import Header from "../components/header"
//import Menu from "../components/menu"

import GameView from "../components/gameview"

import "../components/layout.css"


function Game() {

    return (
        <div>
            <Header />
            <GameView />
        </div>
    )
}
export default Game;