import {useParams, useLocation } from 'react-router-dom';
//import {useParams } from 'react-router-dom';


import Menu from "./menu"
import GameList from "./gamelist"
import Notice from "./notice"
import NoticeView from "./noticeview"

function Main(){
    const location = useLocation();
    const paths = location.pathname.length===1 ? [] : location.pathname.split('/');
    const notice = paths.length<2 || paths[1].length===0  ? false : paths[1]==='notice'

    const param = useParams();
    const id = param.id===undefined  ? '' : param.id
    
    console.log(id)

    return( 
        <main className="main"  >        
            <Menu />    
            {notice? <NoticeView/>:
             id==='' ? <Notice />  : <GameList type={id}/>}
        </main>
    )
    // return( 
    //     <main className="main"  >        
    //         <Menu />    
    //         {id=== '' ? <GameList type={'all'}/>
    //           : <GameList type={id}/>}
    //     </main>
    // )
}


export default Main;
