//import { Container ,Row, Col } from 'react-bootstrap';
import Header from "../components/header"
import Main from "../components/main"
//import Footer from "../components/footer"

//import "../components/layout.css"


function Home() {

    return (
        <div className="layout">
            <Header />            
            <Main />
        </div>
    )
}
export default Home;