import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import Info from '../info'
const info = Info();

function CardView({style,id, title, type, release, genre, dev, img}){

    return (
        <Card style={style}>         
            <Link to={`/Game/${id}`}>
            <Card.Img variant='top' src={`${info.cdn}imge/${img}`}  alt = "title"/> 
            </Link>
            <Card.Body >                
                <div>
                    <p className="title" style={{fontSize:"0.9em"}}>
                        <Link className='link-secondary' to={`/Game/${id}`}>{title} /{release} /{genre} /{dev}  /{type}</Link>
                    </p>
                </div>
            </Card.Body>
        </Card>

    );
}

export default CardView;