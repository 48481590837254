import {useEffect, useState} from "react";
import {Pagination,Container,Row,Col} from 'react-bootstrap';



function PageList({maxIdx,onChangePage}){

    const max = maxIdx===0 ? 1 : maxIdx;
    const [selectIdx, setSelectIdx] = useState(1);
    const [indexs, setIndexs] = useState([1]);

    var movePage = (num)=>{
        setSelectIdx(num)
        onChangePage(num)
    }
    var firstToLastPage = (isFirat)=>{
        
        if(isFirat){
        movePage(1);
        }else{
        movePage(max);
        }
    }
    var prevToNextPage = (isPrev)=>{
        
        if(isPrev){
            if(selectIdx>1) movePage(selectIdx-1);
        }else{
            if(selectIdx<max) movePage(selectIdx+1);
        }
    }
    
    useEffect(()=>{

        let itemindex = [];
        for (let number = 1; number <= max; number++) {
            itemindex.push(number);
        }
        setIndexs(itemindex);

    },[max])
    
    return (
    <Container>
        <Row className="justify-content-md-center">
            <Col xs={'auto'}>
            <Pagination>
                <Pagination.First onClick={()=>firstToLastPage(true)}/>
                <Pagination.Prev onClick={()=>prevToNextPage(true)}/>
                {
                    indexs.map((n)=>(
                        n===selectIdx ? <Pagination.Item key={n} active >{n}</Pagination.Item> 
                                        : <Pagination.Item key={n} onClick={()=>movePage(n)}>{n}</Pagination.Item>
                    ))
                }
                <Pagination.Next onClick={()=>prevToNextPage(false)}/>
                <Pagination.Last onClick={()=>firstToLastPage(false)}/>                            
            </Pagination>
            </Col>
        </Row>
    </Container>
    )
}

export default PageList;