
import {useEffect, useState} from "react";
import {useParams, useNavigate} from 'react-router-dom';
import {Container, Button} from 'react-bootstrap';
//import './tstory.css'

import PocketBase from 'pocketbase';
import Info from '../info'
const info = Info();
const pb = new PocketBase(info.api);

function NoticeView(){
    const navigate = useNavigate();
    const {id} = useParams()
    console.log(id)
    const [loading, setLoading] = useState(true);
    const [notice, setNotice] = useState({});
    useEffect(()=>{

        pb.collection('notice').getList(1, 50, {
            filter: `created >= "2022-01-01 00:00:00" && index = ${id}`,
        })
        .then(json => {
            
            //console.log(json.data.roomlist);
            
            console.log(json.items);
            setNotice(json.items[0]);
            setLoading(false);
        });

    },[id]);
    
    return(<>
   
        <Container >
            {loading?<h1>Loading</h1>
            :
            <div>
                <Button variant="link" onClick={()=>navigate(-1)} style={{fontSize:'1.5rem'}}>Back</Button>
                <div dangerouslySetInnerHTML={ {__html: notice.body} }></div>     
            </div>}
        </Container>            
    </>)
}


export default NoticeView;