import {useEffect, useState} from "react";
import {useParams,useNavigate } from 'react-router-dom';
import {Container,Row,Col,Card,Button} from 'react-bootstrap';
import { EmulatorJS, defaultPathToData } from "react-emulatorjs"

import PocketBase from 'pocketbase';
import Info from '../info'
const info = Info();

const pb = new PocketBase(info.api);

function Play(datas){
    
    

    useEffect(()=>{

        if(datas.core === 'dos'){
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML =`        
            window.dos = Dos(document.getElementById("jsdos"), {});
            window.dos.run("${datas.url}");
            `;
            document.body.appendChild(s);
            return ()=>{

                window.dos.stop();
            }
        }
        
    },[datas])

    console.log("----"+datas.core);
    console.log("----"+datas.url);
    return (
    <Container >        
        <Row className="justify-content-md-center">
            {datas.core === 'dos' ?
            <div style={{width:'680px', height:'480px', maxWidth:'100%'}}>
                <div id="jsdos" style={{width:'100%', height:'100%'}} />
            </div>            
            : <EmulatorJS
                EJS_player = '#game'
                EJS_core={`${datas.core}`} // selected core
                EJS_gameUrl={`${datas.url}`} // rom file
                //EJS_AdUrl = 'https://ads.emulatorjs.org/'
                //EJS_lightgun='false'
                EJS_pathtodata={defaultPathToData}//"https://cdn.emulatorjs.org/stable/data/"
             />
            }
        </Row>
    </Container>
    );
}

function Room({r}){
    let navigate = useNavigate();
    const [play, setPlay] = useState(false);
    useEffect(() => {


    },[play]);

     var idx=0;
    return (
        <div>
            <div>
                <Button variant="link" onClick={()=>navigate(-1)}>Back</Button>
            </div>
        <Card>
            
            {play ? (
            <div>
                <Play core={r.core} url={`${info.cdn}roms/${r.core}/${r.file}`}/>
                <button type="button" className="nes-btn is-primary" style={{margin:'10px', width:'100%'}}
                        onClick={()=>setPlay(false)}>CLOSE</button>
            </div>
            )
            : (
            <div>
                <Card.Img src={`${info.cdn}imge/${r.img}`}  alt = "title"/>
                <button type="button" className="nes-btn is-primary " style={{margin:'10px', width:'100%'}}
                        onClick={()=>setPlay(true)}>PLAY</button>
            </div>
            )}
            <Card.Body>
                <div className="nes-container with-title " >
                    <p className="title" style={{fontSize:"1.2em"}}>{r.title}</p>
                    <div className="lists">
                        <ul className="nes-list is-circle">
                            <li>{r.release}</li>
                            <li>{r.genre}</li>
                            <li>{r.dev}</li>     
                        </ul>
                    </div>
                    <div className="nes-container is-rounded">
                        {r.des.split(/\\n/g).map((r)=>(<p key={idx++}>{r}</p>))}
                    </div>
                </div>    
            </Card.Body>
        </Card>
        
        </div>
    );
}
function GameView(){ 

    const {id} = useParams()
    console.log(id)
    const [loading, setLoading] = useState(true);
    const [roomid, setRoomid] = useState({});
    useEffect(()=>{

        pb.collection('romlist').getList(1, 50, {
            filter: `created >= "2022-01-01 00:00:00" && index = ${id}`,
        })
        .then(json => {
            
            //console.log(json.data.roomlist);
            
            console.log(json.items);
            setRoomid(json.items[0]);
            setLoading(false);
        });

    },[id]);
    

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg={6}>
                {
                    loading 
                    ? (<h1>Loading...</h1>)
                    : (
                        <Room key={id} r={roomid} />
                    )
                }
                </Col>
            </Row>
        </Container>
    );
}

export default GameView;