import {Container,Row,Col} from 'react-bootstrap';
import {useEffect, useState} from "react";

import CardView from './cardview';
import PageList from './pagelist'

import PocketBase from 'pocketbase';
import Info from '../info'
const info = Info()
const pb = new PocketBase(info.api);

function GameList({type}){

    console.log('GameList '+type)

    const [loading, setLoading] = useState(true);
    const [roms, setRoms] = useState([[]]);
    const [selectIdx, setSelectIdx] = useState(1);
    const [maxIdx, setMaxIdx] = useState(1);
    const maxPage = 96;


    var getGame = (typeName)=>{

        //임시로 막아둠
        // setRoms([]);
        // setMaxIdx(1);
        // setSelectIdx(1);
        // setLoading(false);
        
        /** */    
        pb.collection('romlist').getList(1, 1000, {
            filter: typeName==='all' ? `created >= "2022-01-01 00:00:00"` : `created >= "2022-01-01 00:00:00" && type="${typeName}"`,
        })
        .then(json => {
            
            console.log(json.items);
            
            const romlist = json.items;
            
            
            let list = [];
            let push = [];
            for(let i=0; i<romlist.length ;i++){
                romlist[i].uid = romlist[i].id
                romlist[i].id = romlist[i].index;
                list.push(romlist[i]);
                
                if(list.length===maxPage){
                    push.push(list);
                    list = [];
                }               
            }
            if(list.length>0) push.push(list);

            //console.log(push);

            let max = push.length===0?1:push.length;

            setRoms(push);
            setMaxIdx(max);

            let items = [];
            for (let number = 1; number <= max; number++) {
                items.push(number);
            }
            //console.log(items);
            setSelectIdx(1);
            setLoading(false);
        },erro=>{
            console.log('---------- '+erro);
            setSelectIdx(1);
            setLoading(false);
        });
        /**/
    }
    
    useEffect(()=>{
        
        getGame(type);
        
    },[type]);
    //console.log(rooms);
    
    return( 
        <div>    
            <PageList maxIdx={maxIdx} onChangePage={(idx)=>{
                setSelectIdx(idx);
              }} />        
            <Container >
                {
                    loading ? (<h1>Loading...</h1>) :
                    (<Row className="justify-content-md-left">
                        {roms.length>0?roms[selectIdx-1].map((g)=>(
                        
                        <Col xs lg={3} key={g.id}>
                            <CardView style={{margin:"5px", maxWidth:"294px",maxHeight:"300px", minWidth:"200px"}}
                            id={g.id} title={g.title} type={g.type} release={g.release} genre={g.genre} dev={g.dev} img={g.img}/>
                        </Col>                        
                        )):<dev></dev>}
                    </Row>)
                }
            </Container>            
        </div>
    )
}


export default GameList;
